<template>
  <div :class="!viewLayout ? 'bg-layout-simple' : ''">
    <Header v-if="!viewLayout"/>
    <Loader/>
    <div class="container-router">
      <router-view/>
    </div>
    <ContactoFlotante />
    <UsuarioSinCredito />
  </div>
</template>
<script>
import Header from './components/Header.vue'
import Loader from './components/Loader.vue'
import UsuarioSinCredito from './views/UsuarioSinCredito.vue'
import ContactoFlotante from './components/ContactoFlotante.vue'
import { mapMutations, mapState } from 'vuex'
export default {
  components:{
    Header,
    Loader,
    ContactoFlotante,
    UsuarioSinCredito,
  },
  computed:{
    ...mapState(['procesoActual','documentoCliente','showLogout','viewLayout']),
  },
  watch:{
    $route:{
      handler(to,from){
        window.scrollTo(0, 0)
        if (to.query.nitCliente && to.query.celular) {
          setTimeout(() => {
            this.$store.state.loaderIsActive = true
          }, 10);
          this.$store.commit('setCurrentTypeDoc', 'CC')
          this.$store.dispatch('validarCelular', {
            'client_phone': to.query.celular,
            'client_document': to.query.nitCliente
          })
          if (to.query.nitComercio) {
            this.$store.commit('setTempNit', to.query.nitComercio)
          }
          if (to.query.valorPago) {
            this.$store.commit('setTempVal', to.query.valorPago)
          }
        }
        if(to.name == 'ResponseBancolombia' || to.name == 'ResponsePayu'){
          //
        }else if(to.name == 'validar-celular' && from.name != 'validar-documento'){
          this.cambiarProceso('validar-documento')
        }else if(to.name != from.name && this.showLogout){
          this.cambiarProceso(to.name)
        }else if(to.name+from.name != 'validar-celularvalidar-documento'){
          this.cambiarProceso('validar-documento')
        }else if(this.documentoCliente == null){
          this.cambiarProceso('validar-documento')
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations(['cambiarProceso']),
  },
}
</script>
<style lang="scss">
.bg-layout-simple {
  margin: 1rem;
  margin-bottom: 0;
  background: url('./assets/background_layout__1.svg');
  background-color: #FFFFFF;
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: bottom right;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  min-height: 100dvh;
}
</style>
