import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store';

const routes = [
  {
    path: '/',
    name: 'validar-documento',
    component: () => import(/* webpackChunkName: "home" */ '../views/ValidarDocumento.vue'),
    beforeEnter: (to,from,next) => {
      document.title = 'QUAC | Pagar cuota'
      next()
    },
  },
  {
    path: '/validar-celular',
    name: 'validar-celular',
    component: () => import(/* webpackChunkName: "home" */ '../views/ValidarCelular.vue'),
    beforeEnter: (to,from,next) => {
      if(store.state.validar_documento == null){
        store.commit('cambiarProceso','validar-documento')
      }else {
        next()
      }
    },
  },
  {
    path: '/seleccionar-comercio',
    name: 'seleccionar-comercio',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    beforeEnter: (to,from,next) => {
      if(store.state.validar_documento == null){
        console.log('1')
        store.commit('cambiarProceso','validar-documento')
      }else if(store.state.validar_celular == null){
        console.log('2')
        store.commit('cambiarProceso','validar-celular')
      }else {
        next()
      }
    },
  },
  {
    path: '/pagar',
    name: 'seleccionar-cuota',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    beforeEnter: (to,from,next) => {
      if(store.state.validar_documento == null){
        store.commit('cambiarProceso','validar-documento')
      }else if(store.state.validar_celular == null){
        store.commit('cambiarProceso','validar-celular')
      }else {
        next()
      }
    },
  },
  {
    path: '/pagar/prontopago',
    name: 'pago-anticipado',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    beforeEnter: (to,from,next) => {
      if(store.state.validar_documento == null){
        store.commit('cambiarProceso','validar-documento')
      }else {
        next()
      }
    },
  },
  {
    path: '/pagar/otro-valor',
    name: 'valor-personalizado',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    beforeEnter: (to,from,next) => {
      if(store.state.validar_documento == null){
        store.commit('cambiarProceso','validar-documento')
      }else {
        next()
      }
    },
  },
  {
    path: '/pagar/metodo-pago',
    name: 'metodo-pago',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    beforeEnter: (to,from,next) => {
      if(store.state.validar_documento == null){
        store.commit('cambiarProceso','validar-documento')
      }else {
        next()
      }
    },
  },
  {
    path: '/pagar/metodo-pago/pse',
    name: 'metodo-pago-pse',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    beforeEnter: (to,from,next) => {
      if(store.state.validar_documento == null){
        store.commit('cambiarProceso','validar-documento')
      }else {
        next()
      }
    },
  },
  {
    path: '/pagar/metodo-pago/efecty',
    name: 'metodo-pago-efecty',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    beforeEnter: (to,from,next) => {
      if(store.state.validar_documento == null){
        store.commit('cambiarProceso','validar-documento')
      }else {
        next()
      }
    },
  },
  {
    path: '/pagar/metodo-pago/tc',
    name: 'metodo-pago-tarjeta',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    beforeEnter: (to,from,next) => {
      if(store.state.validar_documento == null){
        store.commit('cambiarProceso','validar-documento')
      }else {
        next()
      }
    },
  },
  {
    path: '/token',
    name: 'validar-identidad',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    beforeEnter: (to,from,next) => {
      if(store.state.validar_documento == null){
        store.commit('cambiarProceso','validar-documento')
      // }else if(store.state.validar_envio_token_sms == null){
      //   next({name: 'seleccionar-cuota', replace: true})
      }else{
        next()
      }
    },
  },
  {
    path: '/estado-cuenta',
    name: 'estado-cuenta',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    beforeEnter: (to,from,next) => {
      if(store.state.validar_documento == null){
        store.commit('cambiarProceso','validar-documento')
      // }else if(store.state.validar_token_sms == null){
      //   store.commit('cambiarProceso','seleccionar-cuota')
      }else{
        store.commit('gestionarValidarEnvioTokenSMS',null)
        next()
      }
    },
  },
  {
    path: '/abono-cupo-detalle',
    name: 'ResponsePayu',
    component: () => import(/* webpackChunkName: "home" */ '../views/RespuestaTransaccion.vue')
  },
  {
    path: '/respuesta-bancolombia',
    name: 'ResponseBancolombia',
    component: () => import(/* webpackChunkName: "home" */ '../views/RespuestaTransaccion.vue')
  },
  {
    path: '/respuesta-bancolombia-pp',
    name: 'ResponseBancolombiaPP',
    component: () => import(/* webpackChunkName: "home" */ '../views/RespuestaTransaccionPP.vue')
  },
  {
    path: '/abono-cupo-detalle-pp',
    name: 'ResponsePayUPP',
    component: () => import(/* webpackChunkName: "home" */ '../views/RespuestaTransaccionPP.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if(store.state.procesoActual == 'validar-documento' && to.path != '/' && to.name != 'ResponseBancolombia' && to.name != 'ResponsePayu') {
    window.location.href = '/'
  } else {
    next()
  }
})

export default router
