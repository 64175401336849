export default () => ({
    procesoActual: 'validar-documento',
    errors: [],
    messageError: null,
    loaderIsActive: false,
    tempNit: '',
    tempVal: null,
    documentoCliente: null,
    celularEnmascaradoCliente: null,
    celularCliente: null,
    porcentajeDescuentoProntopago: null,
    pagoMinimo: null,
    totalDeduda: null,
    clienteNombre: null,
    facturasProntopago: [],
    informacionEstadoCuenta: null,
    idsProntoPago: null,
    valorAbono: null,
    procesoAnterior: null,
    bancosPSE: [],
    pseCodeSelect: '',
    franquiciaTarjeta: '',
    typeDocuments: [],
    respuestaTransaccion:{
        state_pol: 0,
        referencia_pago: '',
        referencia_transaccion: '',
        fecha: '',
        valor_total: 0,
        descripcion: '',
        entidad: '',
    },
    cuponEstrategiaReventa: null,
    showModalCuponEstrategiaReventa: false,
    showLogout: null,
    validar_documento: null,
    validar_celular: null,
    validar_envio_token_sms: null,
    validar_token_sms: null,
    showArrowBack: null,
    beneficioQuac: null,
    beneficioQuacSeleccionado: 0,
    comerciosCredito: [],
    comercioCredito: null,
    nitComercio: null,
    viewLayout: false,
    currentTypeDoc: null,
})